import React from 'react';
//import leftArrow from "../../static/images/leftArrow.svg"
function BackButton(props) {
  return (
    <button className='btn' onClick={() => window.history.back()}>
      {props.children ? props.children : 'Back'}
    </button>
  );
}
export default BackButton;
